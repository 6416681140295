<div class="view-dialog">
  <div class="dialoghearder d-flex align-items-center justify-content-between pb-4">
    <p class="mat-dialog-title">{{ 'View Risk' | i18next }}</p>
    <mat-icon class="drawer-close-icon pointer txt-shuttle-grey" mat-dialog-close>close</mat-icon>
  </div>
  <div class="pt-3">
    <conpulse-web-message-popup #messagePopupComponentMenu="menuInOtherComponent"></conpulse-web-message-popup>
    <div class="d-flex align-items-center justify-content-between mb-3">
      <div class="flex-1 mr-3">
        <p class="f-13 f-w-600 mb-1">{{ 'Risk Name' | i18next }}</p>
        <p class="action-title summary f-14 f-w-500 txt-hint">
          {{ risk?.name || '-' }}
        </p>
      </div>
      <div class="flex-1 mr-3">
        <p class="f-13 f-w-600 mb-1">{{ 'Risk Category' | i18next }}</p>
        <p class="action-title summary f-14 f-w-500 txt-hint">
          {{ risk?.category || '-' }}
        </p>
      </div>
    </div>
    <div class="d-flex align-items-center justify-content-between mb-3">
      <div class="flex-1 mr-3">
        <p class="f-13 f-w-600 mb-1">{{ 'Risk Level' | i18next }}</p>
        <div class="d-flex align-items-center" [ngSwitch]="risk.light">
          <ng-container *ngSwitchCase="LightPriorities.high">
            <span class="red-dot"></span>
          </ng-container>
          <ng-container *ngSwitchCase="LightPriorities.medium">
            <span class="amber-dot"></span>
          </ng-container>
          <ng-container *ngSwitchCase="LightPriorities.low">
            <span class="green-dot"></span>
          </ng-container>
          <ng-container *ngSwitchDefault>
            <span class="grey-dot"></span>
          </ng-container>
        </div>
      </div>
      <div class="flex-1">
        <p class="f-13 f-w-600 mb-1">{{ 'Impact' | i18next }}</p>
        <div class="d-flex align-items-center" [ngSwitch]="risk.impact">
          <ng-container *ngSwitchCase="LightPriorities.high">
            <span class="red-dot"></span>
          </ng-container>
          <ng-container *ngSwitchCase="LightPriorities.medium">
            <span class="amber-dot"></span>
          </ng-container>
          <ng-container *ngSwitchCase="LightPriorities.low">
            <span class="green-dot"></span>
          </ng-container>
          <ng-container *ngSwitchDefault>
            <span class="grey-dot"></span>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="d-flex align-items-center justify-content-between mb-3">
      <div class="flex-1 mr-3">
        <p class="f-13 f-w-600 mb-1">{{ 'Likelihood' | i18next }}</p>
        <div class="d-flex align-items-center" [ngSwitch]="risk.likelihood">
          <ng-container *ngSwitchCase="LightPriorities.high">
            <span class="red-dot"></span>
          </ng-container>
          <ng-container *ngSwitchCase="LightPriorities.medium">
            <span class="amber-dot"></span>
          </ng-container>
          <ng-container *ngSwitchCase="LightPriorities.low">
            <span class="green-dot"></span>
          </ng-container>
          <ng-container *ngSwitchDefault>
            <span class="grey-dot"></span>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="d-flex align-items-center justify-content-between mb-3">
      <div class="flex-1 mr-3">
        <p class="f-13 f-w-600 mb-1">{{ 'Description' | i18next }}</p>
        <p class="summary f-14 f-w-500 txt-hint">{{ risk?.description || '-' }}</p>
      </div>
      <div class="flex-1">
        <p class="f-13 f-w-600 mb-1">{{ 'Comments' | i18next }}</p>
        <p class="summary f-14 f-w-500 txt-hint">{{ risk?.comments || '-' }}</p>
      </div>
    </div>
    <div class="d-flex align-items-center justify-content-between mb-3">
      <div class="flex-1 mr-3">
        <p class="f-13 f-w-600 mb-1">{{ 'Created On' | i18next }}</p>
        <p class="summary f-14 f-w-500 txt-hint">{{ risk?.createdAt | date }}</p>
      </div>
      <div class="flex-1">
        <p class="f-13 f-w-600 mb-1">{{ 'Last Modified Date' | i18next }}</p>
        <p class="summary f-14 f-w-500 txt-hint">{{ (risk?.lastUpdated | date) || '-' }}</p>
      </div>
    </div>
    <div class="d-flex align-items-center justify-content-between mb-3">
      <div class="flex-1 mr-3">
        <p class="f-13 f-w-600 mb-1">{{ 'Created By' | i18next }}</p>
        <div
            [matMenuTriggerFor]="messagePopupComponentMenu?.popMenu"
            (click)="openMessagePopup(risk?.createdBy?._id)"
            [ngClass]="{
              pointer: currentUserInfo?._id !== risk?.createdBy?._id,
              disabled: currentUserInfo?._id === risk?.createdBy?._id
            }"
          >
            <span *ngIf="!risk?.createdBy?.picture && risk?.createdBy?.name?.length; else profileImage" class="alphabet mr-2 orange">
              {{ risk?.createdBy?.name?.charAt(0)?.toUpperCase() }}
            </span>
            <ng-template #profileImage>
              <span *ngIf="risk?.createdBy?.picture?.length">
                <img class="profile-image mr-2" src="{{ risk?.createdBy?.picture }}" />
              </span>
            </ng-template>
            <span class="p-info" [ngClass]="{ 'text-underline': currentUserInfo?._id !== risk?.createdBy?._id }">
              {{ risk?.createdBy?.name || '-' }}
            </span>
          </div>
      </div>
      <div class="flex-1">
        <p class="f-13 f-w-600 mb-1">{{ 'Last Modified By' | i18next }}</p>
        <div
            [matMenuTriggerFor]="messagePopupComponentMenu?.popMenu"
            (click)="openMessagePopup(risk?.updatedBy?._id)"
            [ngClass]="{
              pointer: currentUserInfo?._id !== risk?.updatedBy?._id,
              disabled: currentUserInfo?._id === risk?.updatedBy?._id
            }"
          >
            <span *ngIf="!risk?.updatedBy?.picture?.length && risk?.updatedBy?.name?.length; else updatedProfileImage" class="alphabet mr-2 orange">
              {{ risk?.updatedBy?.name?.charAt(0)?.toUpperCase() }}
            </span>
            <ng-template #updatedProfileImage>
              <span *ngIf="risk?.updatedBy?.picture?.length">
                <img class="profile-image mr-2" src="{{ risk?.updatedBy?.picture }}" />
              </span>
            </ng-template>
            <span class="p-info" [ngClass]="{ 'text-underline': currentUserInfo?._id !== risk?.updatedBy?._id }">
              {{ risk?.updatedBy?.name || '-' }}
            </span>
          </div>
      </div>
    </div>
  </div>
  <div class="d-flex justify-content-end pt-4">
    <div class="text-right d-flex">
      <button type="button" class="mr-12" color="accent" mat-stroked-button mat-dialog-close>{{ 'Cancel' | i18next }}</button>
    </div>
  </div>
</div>
