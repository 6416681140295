import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CONSTANTS, AdminService, InvoiceForm, NotificationService, UserService, nonZeroNumbers, zeroToHundredWithDecimalsRegex } from '@conpulse-web/core';
import { get, isEqual } from 'lodash-es';
import { UtilityMethodsService } from '../../../services';

@Component({
  selector: 'conpulse-web-add-edit-invoice',
  templateUrl: './add-edit-invoice.component.html',
  styleUrls: ['./add-edit-invoice.component.scss'],
})
export class AddEditInvoiceComponent implements OnInit {
  isLoading: boolean = false;
  invoiceForm = {} as InvoiceForm;
  initialInvoiceForm = {} as InvoiceForm;
  taxPattern = zeroToHundredWithDecimalsRegex;
  currencyList = [];
  isEqual = isEqual;
  amountPattern = nonZeroNumbers;
  currencySearchText: string = '';
  dropDownItems = [];
  isLegalEntity = false;
  legalEntityId = '';

  constructor(
    private dialogRef: MatDialogRef<AddEditInvoiceComponent>,
    private userService: UserService,
    private notificaionService: NotificationService,
    private adminService: AdminService,
    private dialog: MatDialog,
    private utilityMethod: UtilityMethodsService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (data.isEdit) {
      this.invoiceForm = {
        currency: data.invoiceDetails.currencyId,
        amount: data.invoiceDetails.amount / Math.pow(10, data?.minorUnit),
        taxPerc: data.invoiceDetails.taxPerc,
        memo: data.invoiceDetails.memo,
      };
    } else {
      this.invoiceForm.currency = data.defaultCurrency;
      this.invoiceForm.taxPerc = this.data.applicableTax;
    }
    if (data.isFromLegalEntity) {
      this.isLegalEntity = data.isFromLegalEntity;
      this.legalEntityId = data.legalEntityId;
      if (data.isEdit) {
        this.invoiceForm = {
          currency: data.invoiceDetails.currencyId,
          amount: data.invoiceDetails.amount / Math.pow(10, data?.minorUnit),
          taxPerc: data.applicableTax,
          memo: data.invoiceDetails.memo,
        };
      }
    }
    this.initialInvoiceForm = JSON.parse(JSON.stringify({ ...this.invoiceForm }));
  }

  ngOnInit(): void {
    this.getCurrencyList();
  }

  getCurrencyList() {
    this.isLoading = true;
    this.userService.getAllCurrencyList().subscribe({
      next: (response) => {
        this.isLoading = false;
        this.currencyList = response?.data || [];
        const selectedCurrency = this.currencyList.find((currency) => currency?._id === this.invoiceForm?.currency);
        if (!selectedCurrency) {
          this.invoiceForm.currency = null;
          this.initialInvoiceForm = JSON.parse(JSON.stringify({ ...this.invoiceForm }));
        }
        this.dropDownItems = JSON.parse(JSON.stringify(this.currencyList));
      },
      error: (error) => {
        this.notificaionService.openErrorSnackBar(get(error, 'message', 'Unable to load currency'));
      },
    });
  }

  /**
   * Closes the dialog
   * @param onSubmit if true, after submit dialog closed else cancel action
   */
  closeDialog(onSubmit: boolean) {
    this.dialogRef.close(onSubmit);
  }

  /**
   * The function "clearSearch" clears the search text and resets the dropdown items to the original
   * country list.
   */
  clearSearch() {
    this.currencySearchText = '';
    this.dropDownItems = JSON.parse(JSON.stringify(this.currencyList));
  }

  /**
   * The search function uses a utility method to search for items in a country list based on a given
   * text and updates the dropDownItems accordingly.
   * @param text - The text parameter is the input text that you want to search for in the country list.
   */
  search(text: string): void {
    this.dropDownItems = this.utilityMethod.searchList(text, this.currencyList, 'name');
  }

  submitForm() {
    //Submit form
    this.isLoading = true;
    this.adminService
      .createOrUpdateInvoiceBilling({
        ...this.invoiceForm,
        ...(this.data.isEdit && { _id: this.data.invoiceDetails._id, status: this.data.invoiceDetails.status }),
        clientId: this.data.clientId,
        isUpdate: this.data.isEdit,
        isLegalEntity: this.isLegalEntity,
        legalEntityId: this.legalEntityId || '',
      })
      .subscribe(
        (response) => {
          this.isLoading = false;
          this.dialogRef.close(true);
        },
        (error) => {
          this.notificaionService.openErrorSnackBar(error?.message ? error?.message : CONSTANTS.GENERIC_ERROR_RESPONSE);
          this.dialogRef.close(false);
        }
      );
  }
}
