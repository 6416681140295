import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarComponent } from './snackbar/snackbar.component';
import { I18NextPipe } from 'angular-i18next';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(public snackBar: MatSnackBar, private readonly i18nextPipe: I18NextPipe) { }

  openSuccessSnackBar(message: string, action = 'X') {
    this.snackBar.openFromComponent(SnackbarComponent, {
      data: {
        message: message,
        icon: 'check_circle',
        type: 'success',
      },
      duration: 3000,
      verticalPosition: 'top',
      horizontalPosition: 'center',
      panelClass: ['success-snack-bar'],
    });
  }

  openErrorSnackBar(message: string, action = 'X') {
    this.snackBar.openFromComponent(SnackbarComponent, {
      data: {
        message: this.i18nextPipe.transform(message),
        icon: 'error',
        type: 'error',
      },
      duration: 3000,
      verticalPosition: 'top',
      horizontalPosition: 'center',
      panelClass: ['error-snack-bar'],
    });
  }

  openWarningSnackBar(message: string, action = 'X') {
    this.snackBar.openFromComponent(SnackbarComponent, {
      data: {
        message: message,
        icon: 'warning',
        type: 'warning',
      },
      duration: 3000,
      verticalPosition: 'top',
      horizontalPosition: 'center',
      panelClass: ['warning-snack-bar'],
    });
  }
}
