import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { isEmpty } from 'lodash-es';
import { Router } from '@angular/router';
import { CONSTANTS, NotificationDetails, NotificationService, user } from '@conpulse-web/core';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  isSuperAdmin: boolean;
  user = {} as user;
  notificationDetails = {} as NotificationDetails;
  public firmDetails = new Subject<any>();
  public profileDetails = new Subject<any>();
  public chartData = new Subject<any>();
  public newNotificationData = new Subject<any>();
  public appendixData$ = new Subject<any>();
  public saveAppendixData$ = new Subject<any>();
  public reloadExecution$ = new Subject<any>();

  constructor(private router: Router,private notificationService: NotificationService,) { }

  openAppendix(data) {
    this.appendixData$.next(data);
  }

  public pushFirmDetails(data) {
    this.firmDetails.next(data);
  }

  public getFirmDetailsObservable(): Observable<any> {
    return this.firmDetails.asObservable();
  }

  public pushProfileDetails(data) {
    this.profileDetails.next(data);
  }

  public getProfileDetailsObservable(): Observable<any> {
    return this.profileDetails.asObservable();
  }

  public pushDestroy(data) {
    this.chartData.next(data);
  }

  public getDestroy(): Observable<any> {
    return this.chartData.asObservable();
  }

  public pushNotification(data) {
    this.newNotificationData.next(data);
  }

  public getNotificationObservable(): Observable<any> {
    return this.newNotificationData.asObservable();
  }

  /**
   * Get previous text diff values
   */
  getPreviousValues(data, felid: string, initialData, isInvitedFirm: boolean, optional = 'text') {
    return initialData[felid]?.commonData?.[optional] !== data[felid]?.commonData?.[optional]
      ? initialData[felid]?.commonData?.[optional]
      : isInvitedFirm
        ? data[felid]?.firmData?.[optional]
        : data[felid]?.clientData?.[optional];
  }

  /**
   * get the comment count for specific field
   * @param field name
   * @param commentCountList tab field list
   * @returns field comment count
   */
  getCommentCount(field, commentCountList) {
    if (commentCountList.length) {
      const fieldDetails = commentCountList.find((data) => data._id === field);
      return fieldDetails ? fieldDetails.internalCount + fieldDetails.publicCount : 0;
    } else {
      return 0;
    }
  }

  /**
   * get the comment count for specific field in dynamic tab
   * @param field id
   * @param commentCountList tab field list
   * @returns field comment count
   */
  getDynamicCommentCount(field, commentCountList) {
    if (commentCountList?.length && field) {
      const fieldDetails = commentCountList.find((data) => data._id.toString() === field.toString());
      return fieldDetails ? fieldDetails.internalCount + fieldDetails.publicCount : 0;
    } else {
      return 0;
    }
  }

  /**
   * check the quill editor value is empty or not
   * @param value string
   * @returns boolean
   */
  checkQuillValueIsEmpty(value: string) {
    if (value) {
      return isEmpty(value.replace(/<[^>]+>(&nbsp;|\s)*<\/[^>]+>/g, '').replace(/&nbsp;/g, '').replace(/\n/g, '').trim()) && !value?.includes('<img');
    } else {
      return true;
    }
  }

  trimQuillValue(value) {
    return this.checkQuillValueIsEmpty(value) ? '' : value;
  }

  cloneDeep(data) {
    return JSON.parse(JSON.stringify(data));
  }

  isValidateEnabled(lastChangedBy, isClient, isValidated, canEdit = true) {
    if (lastChangedBy === 'client') {
      return !isClient && isValidated === 2 && canEdit;
    } else {
      return lastChangedBy === 'provider' && isClient && isValidated === 2 && canEdit;
    }
  }

  onClickNavigationBasedOnNotification(notificationDetail, user, profileDetails, isSuperAdmin) {
    let actionId = typeof notificationDetail?.data == 'string' ? JSON.parse(notificationDetail?.data)?._id : notificationDetail?.data?.id;
    switch (notificationDetail?.type) {
      case 'Create':
        this.openApproveDrawer(notificationDetail?.data?._id, notificationDetail?._id);
        break;
      case 'SurveyAnswer':
        this.openSurveyAnswer(notificationDetail?.contactId?._id, notificationDetail?.data?.projectId?._id);
        break;
      case 'ActionAdded':
        isSuperAdmin ? this.openAdminAction(notificationDetail?.data?._id) : this.openAction(notificationDetail?.data?._id || actionId, profileDetails);
        break;
      case 'ActionCompleted':
        isSuperAdmin ? this.openAdminAction(notificationDetail?.data?._id) : this.openAction(notificationDetail?.data?._id || actionId, profileDetails);
        break;
      case 'ProjectRequestSent':
        const notificationValue = typeof notificationDetail?.data == 'string' ? JSON.parse(notificationDetail?.data) : notificationDetail?.data;
        this.openProjectReview(notificationValue?._id, 'review');
        break;
      case 'projectRequestApproval':
        this.openProjectReview(notificationDetail?.data?._id, 'view');
        break;
      case 'onboardingInvite':
        this.editOnBoardingData(notificationDetail?.data?.clientId, notificationDetail?.data?.legalEntityId);
        break;
      case 'validatedOnboardingData':
        this.editOnBoardingData(notificationDetail?.data?.clientId, notificationDetail?.data?.legalEntityId);
        break;
      case 'legalEntityChangeRequest':
        this.openOnBoardingData(notificationDetail?.data?.firmId, notificationDetail?.data?.legalEntityId);
        break;
      case 'submitOnboardingData':
        this.openOnBoardingData(notificationDetail?.data?.firmId, notificationDetail?.data?.legalEntityId);
        break;
      case 'ApprovedChangeRequestForClient':
        this.openLegalEntityInfo(notificationDetail?.data?.firmId, notificationDetail?.data?.legalEntityId, true);
        break;
      case 'ApprovedChangeRequestForAdmin':
        this.openLegalEntityInfo(notificationDetail?.data?.firmId, notificationDetail?.data?.legalEntityId, false);
        break;
      case 'rejectedChangeRequestForAdmin':
        this.openLegalEntityInfo(notificationDetail?.data?.firmId, notificationDetail?.data?.legalEntityId, false);
        break;
      case 'DeleteLegalEntity':
        this.openFirm(notificationDetail?.data?.firmId);
        break;
      case 'customNotification':
        this.openAction(notificationDetail?.data?.actionDetails?._id, profileDetails);
        break;
      case 'firmAccountUpdated':
        return this.router.navigate([CONSTANTS.firmAccountUrl.accountUrl]);
      case 'clientAccountUpdated':
        return this.router.navigate([CONSTANTS.clientAccount.accountUrl]);
      case 'legalEntityActivated':
        this.openLegalEntity(notificationDetail?.data?._id);
        break;
      case 'legalEntityDeactivated':
        this.openLegalEntity(notificationDetail?.data?._id);
        break;
      case 'clientLegalEntityActivated':
        this.openLegalEntity(notificationDetail?.data?._id);
        break;
      case 'clientLegalEntityDeactivated':
        this.openLegalEntity(notificationDetail?.data?._id);
        break;
      case 'userInviteReq':
        this.router.navigate(['/dashboard/users/invite-requests']);
        break;
      case 'billingInfoUpdated':
        this.router.navigate(['/dashboard/account-info/accounts-billing/billing-info']);
        break;
      case 'briefingInvitaionSent':
        this.router.navigate(['/invitation-dashboard/projects/view/', notificationDetail?.data?.projectId], {
          queryParams: { status: notificationDetail?.data?.invitationStatus, isBriefing: true },
        });
        break;
      case 'announcementAdded':
      case 'announcementUpdated':
        this.router.navigate(
          [`/${CONSTANTS.CF_PROFILE_ROLES.includes(user?.role) ? 'invitation-dashboard' : 'dashboard'}/projects/view/`, notificationDetail?.dataId],
          {
            queryParams: { to: 'announcement' },
          }
        );
        break;
      case 'addedAsAdminContact':
      case 'legalEntityUpdatedBySuperAdmin':
        this.router.navigate(['/invitation-dashboard/legal-entity/edit', notificationDetail?.data?._id]);
        break;
      case 'clientInvoiceStatus':
      case 'invoiceStatusChanged':
        const notificationData = notificationDetail.data;
        CONSTANTS.CF_PROFILE_ROLES.includes(user?.role)
          ? this.router.navigate([`/invitation-dashboard/legal-entity/edit/${notificationData.legalEntityId}`], {
            queryParams: {
              invoiceId: notificationData.invoiceId,
            },
          })
          : this.router.navigate([`/dashboard/account-info/accounts-billing/invoice-billing`]);
        break;
      case 'submitKysData':
        if (typeof notificationDetail.data === 'string') {
          notificationDetail.data = JSON.parse(notificationDetail.data);
        }
        const notificationContents = notificationDetail.data;
        CONSTANTS.CF_PROFILE_ROLES.includes(user?.role)
          ? this.router.navigate([`/invitation-dashboard/legal-entity/edit/${notificationContents.legalEntityId}/kys-question`], {
            queryParams: { questionnaireId: notificationContents.questionnaireId },
          })
          : this.router.navigate(
            [
              `dashboard/consulting-firms/viewProfile/${notificationContents.firmId}/company-profile/legal-entity/edit/${notificationContents.legalEntityId}/kys-question`,
            ],
            {
              queryParams: { questionnaireId: notificationContents.questionnaireId },
            }
          );
        break;
      case 'validatedKysData':
      case 'projectManagementInvoiceApproved':
        if (isSuperAdmin) {
          this.router.navigate([`/dashboard/view-project/${notificationDetail.data._id}`]);
        } else {
          this.router.navigate([`/invitation-dashboard/projects/view/${notificationDetail.data._id}`], {
            queryParams: { step: 'management', tab: 'invoicing-payment' },
          });
        }
        break;
      case 'projectManagementInvoicePaymentStatusChange':
        if (isSuperAdmin) {
          this.router.navigate([`/dashboard/view-project/${notificationDetail.data._id}`]);
        } else {
          this.router.navigate([`/invitation-dashboard/projects/view/${notificationDetail.data._id}`], {
            queryParams: { step: 'management', tab: 'invoicing-payment' },
          });
        }
        break;
      case 'notifySuperAdminOnceValidated':
        if (isSuperAdmin) {
          this.router.navigate([`/dashboard/view-project/${notificationDetail.data._id}`]);
        } else {
          this.router.navigate([`/invitation-dashboard/projects/view/${notificationDetail.data._id}`], {
            queryParams: { step: 'management', tab: 'invoicing-payment' },
          });
        }
        break;
      case 'clientSuggestedCompany':
        this.router.navigate(['/dashboard/suggest-firms']);
        break;
      case 'projectManagementInvoiceUploaded':
        this.router.navigate([`/dashboard/projects/view/${notificationDetail.data._id}`], {
          queryParams: { step: 'management', tab: 'invoicing-payment' },
        });
        break;
      case 'kysInvite':
        if (typeof notificationDetail.data === 'string') {
          notificationDetail.data = JSON.parse(notificationDetail.data);
        }
        const notificationContent = notificationDetail.data;
        CONSTANTS.CF_PROFILE_ROLES.includes(user?.role)
          ? this.router.navigate([`/invitation-dashboard/legal-entity/edit/${notificationContent.legalEntityId}/kys-question`], {
            queryParams: { questionnaireId: notificationContent.questionnaireId },
          })
          : this.router.navigate(
            [
              `dashboard/consulting-firms/viewProfile/${notificationContent.firmId}/company-profile/legal-entity/edit/${notificationContent.legalEntityId}/kys-question`,
            ],
            {
              queryParams: { questionnaireId: notificationContent.questionnaireId },
            }
          );
        break;
      case 'meetingInvite':
        if (typeof notificationDetail.data === 'string') {
          notificationDetail.data = JSON.parse(notificationDetail.data);
        }
        const link = notificationDetail.data.link;
        window.open(link);
        break;
      case 'clientAdminChangeReq':
        return this.router.navigate(['/dashboard/clients/admin-change-requests']);
      case 'sourcingInviteSent':
      case 'sourcingInviteReSend':
        this.router.navigate(['/invitation-dashboard/opportunities'], {
          queryParams: { requestId: notificationDetail?.data?.projectId },
        });
        break;
      case 'addSignatoryMember':
      case 'removeSignatoryMember':
        this.navigateToContract(notificationDetail?.data, user);
        break;
      case 'singatureModeChange':
      case 'envelopeSentForSignature':
      case 'changedFinalPdf':
        const parsedData = typeof notificationDetail?.data == 'string' ? JSON.parse(notificationDetail.data) : notificationDetail.data;
        this.navigateToContract(parsedData, user);
        break;
    }
    if (
      ['projectRequestRejection', 'projectTeamAdded', 'projectTeamChange', 'projectDataChange', 'projectRenotify', 'demandPending'].includes(
        notificationDetail?.type
      )
    ) {
      this.openProjectReview(notificationDetail?.data?._id, 'view');
    }
    if (
      [
        'rfpSourcingApproved',
        'rfpSourcingRejected',
        'rfpSourcingRfpVisible',
        'Survey',
        'rfpAnsweredQuestion',
        'rfpQuestionPost',
        'rfpQuestionDelete',
        'proposalEvaluatorTeamAdded',
        'proposalEvaluatorTeamMemberAdded',
        'proposalEvaluatorTeamRemoved',
        'proposalEvaluatorTeamMemberRemoved',
        'pmInvoiceSubmittedBySuperAdminForProvider',
        'pmInvoiceSubmittedBySuperAdminForClient'
      ].includes(notificationDetail?.type)
    ) {
      this.openProject(notificationDetail?.data?._id, profileDetails);
    }
    if (['LegalEntityStripeCustomerCreated', 'StripeUpdateLegalEntityCustomerDetails'].includes(notificationDetail?.type)) {
      if (isSuperAdmin) {
        this.openAdminLegalEntityInfo(notificationDetail?.data?.firmId, notificationDetail?.data?._id);
      } else {
        this.openLegalEntityInfo(notificationDetail?.data?.firmId, notificationDetail?.data?._id, false);
      }
    }
  }

  private openAdminAction(actionId) {
    const url = CONSTANTS.superAdminUrl.actionUrl;
    return this.router.navigate([url], { queryParams: { mode: 'view', actionId: actionId } });
  }

  /**
   * Open legal entity data page in super admin login
   * @param firmId
   * @param legalEntityId
   */
  private openAdminLegalEntityInfo(firmId: string, legalEntityId: string) {
    if (!isEmpty(firmId) && !isEmpty(legalEntityId)) {
      this.router.navigate([`dashboard/consulting-firms/viewProfile/${firmId}/company-profile/legal-entity/edit/${legalEntityId}`]);
    } else {
      this.notificationService.openErrorSnackBar('Unable to redirect to legal entity page');
    }
  }

  private openApproveDrawer(id, nid) {
    return this.router.navigate(['/dashboard/provider/approve'], { queryParams: { id, nid } });
  }

  private openSurveyAnswer(contactId, projectId) {
    return this.router.navigate(['/dashboard/projects/survey/answers'], { queryParams: { contactId, projectId } });
  }

  private openAction(actionId, profileDetails) {
    let url = CONSTANTS.clientURL.actionUrl;
    if (profileDetails?.role === CONSTANTS.role.firmAdmin || profileDetails?.role === CONSTANTS.role.firmUser) {
      url = CONSTANTS.firmURL.actionURL;
    }
    return this.router.navigate([url], { queryParams: { mode: 'view', actionId: actionId } });
  }

  /**
   * navigate to legal entity account tab
   */
  private openLegalEntity(entityId) {
    let url = CONSTANTS.legalEntityAccount.accountUrl;
    return this.router.navigate([url, entityId]);
  }

  private openProjectReview(id: string, type: string) {
    this.router.navigate(['/dashboard/projects/view/demand'], {
      queryParams: {
        id: id,
        type: type,
      },
    });
  }

  /**
   * Open legal entity onboarding data page
   * @param firmId
   * @param legalEntityId
   */
  private openOnBoardingData(firmId: string, legalEntityId: string) {
    this.router.navigate([`/dashboard/provider/viewProfile/${firmId}/legal-entities/${legalEntityId}/on-boarding`]);
  }

  /**
   * open legal entity onboarding data page to update
   * @param clientId
   * @param legalEntityId
   */
  private editOnBoardingData(clientId: string, legalEntityId: string) {
    this.router.navigate([`/invitation-dashboard/legal-entity/edit/${legalEntityId}/on-boarding`], {
      queryParams: {
        clientId: clientId,
      },
    });
  }

  /**
   * Redirect to project Page
   * @param projectId
   */
  private openProject(projectId, profileDetails) {
    profileDetails?.role < 5
      ? this.router.navigate([`dashboard/projects/view/${projectId}`])
      : this.router.navigate([`invitation-dashboard/projects/view/${projectId}`]);
  }

  /**
   * Open legal entity onboarding data page
   * @param firmId
   * @param legalEntityId
   */
  private openLegalEntityInfo(firmId: string, legalEntityId: string, isClient) {
    if (!isEmpty(firmId) && !isEmpty(legalEntityId)) {
      isClient
        ? this.router.navigate([`/dashboard/provider/viewProfile/${firmId}/legal-entities/${legalEntityId}/info`])
        : this.router.navigate([`invitation-dashboard/legal-entity/edit/${legalEntityId}`]);
    } else {
      this.notificationService.openErrorSnackBar('Unable to redirect to legal entity page');
    }
  }

  /**
   * Open firm page
   * @param firmId
   * @param legalEntityId
   */
  private openFirm(firmId: string) {
    this.router.navigate([`dashboard/provider/viewProfile/${firmId}/legal-entities`]);
  }

  private navigateToContract(data, user) {
    switch (data?.type) {
      case 'NDA':
        this.router.navigate([`/${user.companyId ? 'dashboard' : 'invitation-dashboard'}/administrative/nda-wizard`], {
          queryParams: { id: data?._id, tab: 'execution' },
        });
        break;
      case 'MSA':
        this.router.navigate([`/${user.companyId ? 'dashboard' : 'invitation-dashboard'}/administrative/msa-wizard`], {
          queryParams: { id: data?._id, tab: 'execution' },
        });
        break;
      case 'SOW':
        this.router.navigate([`/${user.companyId ? 'dashboard' : 'invitation-dashboard'}/projects/view`, data?._id], {
          queryParams: {
            step: 'sow',
            tab: 'execution',
          },
        });
        break;
      case 'Change Order':
      case 'changeorder':
        this.router.navigate([`/${user.companyId ? 'dashboard' : 'invitation-dashboard'}/projects/view`, data?._id], {
          queryParams: {
            step: 'management',
            tab: 'changes',
            subtab: 'execution',
            firmId: data?.firmId || null,
          },
        });
        break;
    }
  }
}
